import React from "react";

import logoColored from '../../images/logo/logo-theme.png';

function BreadCrumbSection({ title, text }) {
    return (
        <section id="breadcrumb" className="text-light">
            <div className="shadow-pill-parent wavy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="descriptor pt-5 pb-5">
                                <h2 className="theme-text">
                                    {title}
                                </h2>
                                <p className="offset-underline"
                                    dangerouslySetInnerHTML={{ __html: text }}>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-xl-inline-block">
                            <div className="p-5 text-center">
                                <img src={logoColored} alt="Cash Point Colored Logo" width="150" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BreadCrumbSection;