import React from "react";
import {
    APP_NAME,
    DROP_DOWN_LINKS_ALT,
} from "../constants";
import Header from "../components/Headers/Header";
import BreadCrumbSection from '../components/Sections/BreadCrumbSection';
import PillAnimation from "../components/Animation/PillAnimation";
import AboutSection from "../components/Sections/AboutSection";
import ScrollToTop from "../components/Utilities/scrollToTop";

const AboutPage = () => {
    return (
        <>
            <Header
                title={APP_NAME}
                dropDownText="More"
                dropDownLinks={DROP_DOWN_LINKS_ALT}
            />
            <BreadCrumbSection
                title="About US"
                text="Who is Cash Point?<br />Why Cash Point?<br />"
            />
            <PillAnimation />
            <AboutSection />
            <ScrollToTop />
        </>
    );
};

export default AboutPage;