import React from "react";
import {
    APP_NAME,
    DROP_DOWN_LINKS_ALT,
    TERMS_AND_CONDITION,
} from "../constants";
import Header from "../components/Headers/Header";
import BreadCrumbSection from '../components/Sections/BreadCrumbSection';
import PillAnimation from "../components/Animation/PillAnimation";
import ScrollToTop from "../components/Utilities/scrollToTop";
import TermsConditionSection from "../components/Sections/TermsConditionSection";


const TermsAndConditionPage = () => {
    return (
        <>
            <Header
                title={APP_NAME}
                dropDownText="More"
                dropDownLinks={DROP_DOWN_LINKS_ALT}
            />
            <BreadCrumbSection
                title="Terms and Conditions"
                text="We have our rules..."
            />
            <PillAnimation />
            <TermsConditionSection termsConditionData={TERMS_AND_CONDITION}/>
            <ScrollToTop />
        </>
    )
}

export default TermsAndConditionPage;