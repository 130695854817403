import React from "react";
import Icon from "../Utilities/icon";

function RapidTransactionSection() {
    return (
        <section id="rapid-transaction" className="position-relative text-light">
            <div className="pt-5 pb-5 wavy">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out">
                            <span>The art of speed</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            <Icon name="Zap" />
                            Rapid Transaction
                        </h2>
                        <p className="offset-underline">Liberating you from the constraints of time, location, and complexity.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <p className="mt-5">
                                We understand that in today's fast-paced landscape, speed and efficiency are paramount. That's why we've harnessed
                                cutting-edge technology to bring you lightning-fast bill payments and SMM services that keep up with your dynamic
                                lifestyle. We utilize the best services and providers closet to you to give you the best latency and services
                                with up to 99.9% uptime.
                            </p>
                        </div>
                        <div className="col md-6 col-xs-12">
                            <div className="bolt-holder">
                                <div className="bolt">
                                    <svg viewBox="0 0 170 57" className="white left">
                                        <path
                                            d="M36.2701759,17.9733192 C-0.981139498,45.4810755 -7.86361824,57.6618438 15.6227397,54.5156241 C50.8522766,49.7962945 201.109341,31.1461782 161.361488,2">
                                        </path>
                                    </svg>
                                    <svg viewBox="0 0 170 57" className="white right">
                                        <path
                                            d="M36.2701759,17.9733192 C-0.981139498,45.4810755 -7.86361824,57.6618438 15.6227397,54.5156241 C50.8522766,49.7962945 201.109341,31.1461782 161.361488,2">
                                        </path>
                                    </svg>
                                    <div>
                                        <span></span>
                                    </div>
                                    <svg viewBox="0 0 112 44" className="circle">
                                        <path
                                            d="M96.9355003,2 C109.46067,13.4022454 131.614152,42 56.9906735,42 C-17.6328048,42 1.51790702,13.5493875 13.0513641,2">
                                        </path>
                                    </svg>
                                    <svg viewBox="0 0 70 3" className="line left">
                                        <path transform="translate(-2.000000, 0.000000)" d="M2,1.5 L70,1.5"></path>
                                    </svg>
                                    <svg viewBox="0 0 70 3" className="line right">
                                        <path transform="translate(-2.000000, 0.000000)" d="M2,1.5 L70,1.5"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RapidTransactionSection;