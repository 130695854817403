import React from "react";

import questionSVG from '../../images/svg/question.svg';

function AboutSection(){
    return (

        <section id="about-us" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Who we are?</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            Cash Point
                        </h2>
                        <p>
                            At Cash Point, we believe that modern financial solutions should be convenient, accessible,
                            and tailored to your needs.
                            As a dynamic and forward-thinking company, we have curated a comprehensive range of services
                            that redefine the way you
                            manage your finances and embrace the digital age.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="descriptor mb-5">
                                <img src={questionSVG} alt="Question SVG" width="66%" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="shout-out-dark mb-2">
                                <span><strong>01. Effortless Bills Payment:</strong></span>
                            </div>
                            <p>
                                Say goodbye to the hassle of traditional bill payment methods. With Cash Point, you can
                                easily settle your airtime,
                                electricity, internet, cable, and more, all from a single platform. Our user-friendly
                                interface ensures that managing
                                your bills becomes a seamless part of your routine.
                            </p>

                            <div className="shout-out-dark mb-2">
                                <span><strong>02. Seamless SMM Services:</strong></span>
                            </div>
                            <p>In the digital age, social media is more than just a platform for connections, it's a
                                powerful
                                tool for businesses. Our
                                Social Media Marketing (SMM) services empower you to harness the potential of social
                                platforms. From strategic campaigns
                                to engaging content, we help you make a meaningful impact in the digital sphere.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;