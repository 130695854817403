import React from "react";
import Icon from "../Utilities/icon";


function Services({iconName, name, serviceInfo}){
    return(
        <div className="service">
            <Icon name={iconName} />
            <h2>{name}</h2>
            <p>
                {serviceInfo},
            </p>
        </div>
    );
}

function ServicesSection({services}) {
    return (
        <section id="services" className="bg-white position-relative">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Our Services</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            Our Awesome Services
                        </h2>
                    </div>
                    <div className="service-row mt-5">
                        {services.map((service, index) => (
                            <Services key={index} {...service} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesSection;