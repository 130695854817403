import React from "react";

function QandA({ question, answer, isFirst }) {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`heading-${question.substr(0, 5).replace(/\s+/g, "-").toLowerCase()}`}>
                <button
                    className={`accordion-button${!isFirst ? " collapsed" : ""}`} // Add the "collapsed" class for all but the first item
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${question.substr(0, 5).replace(/\s+/g, "-").toLowerCase()}`}
                    aria-expanded={isFirst ? "true" : "false"}
                    aria-controls={`collapse-${question.substr(0, 5).replace(/\s+/g, "-").toLowerCase()}`}
                >
                    {question}
                </button>
            </h2>
            <div
                id={`collapse-${question.substr(0, 5).replace(/\s+/g, "-").toLowerCase()}`}
                className={`accordion-collapse collapse${isFirst ? " show" : ""}`}
                aria-labelledby={`heading-${question.substr(0, 5).replace(/\s+/g, "-").toLowerCase()}`}
                data-bs-parent="#faqAccordion"
            >
                <div className="accordion-body">
                    <p>{answer}</p>
                </div>
            </div>
        </div>
    );
}

function FaqSection({ faqData }) {
    return (
        <section id="faq" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Faq</span>
                        </div>
                        <h2 className="theme-text mt-3">Frequently Asked Questions</h2>
                        <p className="offset-underline">What our community always asks us</p>
                    </div>
                    <div className="accordion mt-3" id="faqAccordion">
                        {faqData.map((item, index) => (
                            <QandA key={index} {...item} isFirst={index === 0} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FaqSection;
