import React from "react";
import Icon from "../Utilities/icon";

import {SMM_URL} from '../../constants';
import facebookIcon from '../../images/social/facebook.png';
import instagramIcon from '../../images/social/instagram.png';
import telegramIcon from '../../images/social/telegram.png';
import threadsIcon from '../../images/social/threads.webp';
import twitterIcon from '../../images/social/twitter.png';
import youtubeIcon from '../../images/social/youtube.png';


const Images = [
    facebookIcon,
    instagramIcon,
    telegramIcon,
    threadsIcon,
    twitterIcon,
    youtubeIcon
];

function SMMSection() {
    return (
        <section id="social-media-marketing" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>SMM</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            <Icon name="Megaphone" /><br />
                            Social Media Marketing
                        </h2>
                        <p className="offset-underline">
                            Promote and boost your brand in all the famous social media applications
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="justify-content-center align-content-center d-flex mt-5">
                                <div className="fader-cards">
                                    {Images.map((Image, index) => (
                                        <Card key={index} imageName={Image} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="descriptor">
                                <p>
                                    In today's digital age, where connectivity and engagement drive business growth, Social
                                    Media Marketing (SMM) has emerged as a powerful tool to expand your brand's reach, connect with your audience, and achieve remarkable success. SMM offers an avenue to harness the influence of social platforms and leverage it to drive your business goals forward.
                                </p>
                                <p>
                                    Embrace the power of Social Media Marketing to foster genuine connections, amplify your brand's voice, and drive growth. In a world where digital interactions hold tremendous influence, SMM empowers you to make your presence known and carve a lasting impact.
                                </p>
                                <p>
                                    With Cash Point, you can drive traffic, audience, and much more for your brand at a cheap and affordable rate.
                                </p>
                                <a className="btn btn-lg btn-dark bg-black text-uppercase underline-offset fw-bold" href={SMM_URL} target="_blank" rel="noopener noreferrer">Get Started with SMM</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Card({ imageName: socialImage }) {
    return (
        <div className="fader-card">
            <img className="card-front-image card-image" src={socialImage} alt="Social" />
            <div className="card-faders">
                {[...Array(8)].map((_, index) => (
                    <img key={index} className="card-fader card-image" src={socialImage} alt="Social" />
                ))}
            </div>
        </div>
    );
}

export default SMMSection;
