import React from "react";


import appStoreIcon from '../../images/others/app-store.png';
import playStoreIcon from '../../images/others/play-store.png';

function DownloadSection({ appleStoreLink, playStoreLink }) {
    return (
        <section id="download-app" className="position-relative text-light">
            <div className="pt-5 pb-5 wavy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-header">
                                <div className="shout-out">
                                    <span>Ready to install ?</span>
                                </div>
                                <h2 className="theme-text mt-3">
                                    Download cash Point
                                </h2>
                                <p className="offset-underline">
                                    Available for iOS and Android.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-center mt-3">
                                <div className="app-download-btn">
                                    <a href={appleStoreLink} target="_blank" rel="noreferrer">
                                        <img src={appStoreIcon} width="150" alt="App Store Download" />
                                    </a>
                                </div>
                                <div className="app-download-btn">
                                    <a href={playStoreLink} target="_blank" rel="noreferrer">
                                        <img src={playStoreIcon} width="150" alt="Play Store Download" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DownloadSection;