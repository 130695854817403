import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  FOOTER_LINKS
} from "./constants";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import DownloadSection from "./components/Sections/DownloadSection";
import Footer from "./components/Footers/Footer";

function App() {
  return (
    <main className="container-fluid">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionPage />} />
        </Routes>
        <DownloadSection
          appleStoreLink={APP_STORE_LINK}
          playStoreLink={PLAY_STORE_LINK}
        />
        <Footer
          footerLinks={FOOTER_LINKS}
        />
      </Router>
    </main>
  );
}

export default App;
