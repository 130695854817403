// PillAnimation.js
import React, { useEffect } from "react";
import { TimelineMax, Back } from "gsap";

function PillAnimation() {
    const numPills = 10;

    useEffect(() => {
        function createPill(parentDiv) {
            const pill = document.createElement("div");
            pill.classList.add("shadow-pill");

            const randomTop = Math.random() * (parentDiv.clientHeight - 40);
            const randomLeft = Math.random() * (parentDiv.clientWidth - 100);

            pill.style.top = `${randomTop}px`;
            pill.style.left = `${randomLeft}px`;
            parentDiv.appendChild(pill);

            setTimeout(function () {
                pill.remove();
                createPill(parentDiv);
            }, 500);
        }

        document.querySelectorAll(".shadow-pill-parent").forEach((parentDiv) => {
            for (let i = 0; i < numPills; i++) {
                createPill(parentDiv);
            }
        });

        document.querySelectorAll(".bolt").forEach((bolt) => {
            const div = bolt.querySelector("div");

            bolt.classList.add("animate");

            const tween = new TimelineMax({
                onComplete() {
                    bolt.classList.remove("animate");
                    repeat();
                },
            })
                .set(div, {
                    rotation: 360,
                })
                .to(div, 0.7, {
                    y: 80,
                    rotation: 370,
                })
                .to(div, 0.6, {
                    y: -140,
                    rotation: 20,
                })
                .to(div, 0.1, {
                    rotation: -24,
                    y: 80,
                })
                .to(div, 0.8, {
                    ease: Back.easeOut.config(1.6),
                    rotation: 0,
                    y: 0,
                });

            function repeat() {
                setTimeout(() => {
                    bolt.classList.add("animate");
                    tween.restart();
                }, 400);
            }
        });
    }, []);

    return (
        <div>
            {/* Your JSX content goes here */}
        </div>
    );
}

export default PillAnimation;
