import React from "react";
import Icon from "../Utilities/icon";

function WhyUs({ iconName, shortText, longText }) {
    return (
        <div className="col-lg-4 col-sm-6">
            <div className="item">
                <span className="icon">
                    <Icon name={iconName} />
                </span>
                <h6>{shortText}</h6>
                <p>
                    {longText}
                </p>
            </div>
        </div>
    );
}

function WhyUsSection({ whyUs }) {
    return (
        <section id="why-us" className="position-relative text-light">
            <div className="pt-5 pb-5 wavy">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out">
                            <span>Why Us</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            Why Choose Cash Point ?
                        </h2>
                    </div>
                    <div className="row mt-5">
                        {whyUs.map((item, index) => (
                            <WhyUs key={index} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyUsSection;