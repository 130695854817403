import React from "react";
import { TERMS_CONDITION_EFFECTIVE_DATE } from '../../constants'

function TermsConditionContent({ content }) {
    return (
        <div className="row">
            <div className="col-md-12">
                {content.map((item, index) => (
                    <ContentItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
}

function ContentItem({ type, text, items, linkText, linkHref }) {
    switch (type) {
        case "paragraph":
            return <p>{text}</p>;
        case "list":
            return (
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
        case "link":
            return (
                <p>
                    <a href={linkHref}>{linkText}</a>
                </p>
            );
        case "bold":
            return (
                <p>
                    <b className="text-uppercase">{text}</b>
                </p>
            )
        default:
            return null;
    }
}

function TermsConditionSection({ termsConditionData }) {
    return (
        <section id="Privacy-policy" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Your Terms, Our Condition</span>
                        </div>
                        <h2 className="theme-text mt-3">Terms and Conditions</h2>
                        <p dangerouslySetInnerHTML={{ __html: TERMS_CONDITION_EFFECTIVE_DATE }}></p>
                    </div>
                    {termsConditionData.map((section, index) => (
                        <div className="text-justify" key={index}>
                            <h2>{section.title}</h2>
                            <TermsConditionContent content={section.content} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default TermsConditionSection;