import React from "react";
import { Link } from 'react-router-dom'
import Icon from "../Utilities/icon";

import logoDark from '../../images/logo/logo-dark.png';

function Footer({ footerLinks }) {
    return (
        <footer id="footer" className="position-relative bg-white align-content-start">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-3 col-xs-12">
                        <Link to="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
                            <img src={logoDark} width="75" alt="Cash Point Logo" />
                        </Link>
                        <p>
                            Say goodbye to long queues and complicated payment processes,<br />
                            Say hello to a smarter and faster way to pay and manage bills.
                        </p>
                        <p className="text-muted">© 2023</p>
                    </div>
                    {footerLinks.map((section, index) => (
                        <div className="col-md-3 col-xs-12 mb-3" key={index}>
                            <FooterLinks title={section.title} links={section.links} icons={section.icons} />
                        </div>
                    ))}
                </div>
            </div>
        </footer>
    );
}

function isInternalLink(href) {
    return /^\/(?!\/)/.test(href);
}


function FooterLinks({ title, links, icons }) {
    return (
        <div>
            <h5 className="theme-text">{title}</h5>
            <ul className="nav flex-column">
                {links.map((link, index) => (
                    <li className="nav-item mb-2" key={index}>
                        {isInternalLink(link.href) ? (
                            <Link className="nav-link p-0" to={link.href || "#"}>
                                {icons && icons[index] && <span className="me-1"><Icon name={icons[index]} /> </span>}
                                <span>{link.text}</span>
                            </Link>
                        ) : (
                            <a className="nav-link p-0" href={link.href || "#"}>
                                {icons && icons[index] && <span className="me-1"><Icon name={icons[index]} /> </span>}
                                <span>{link.text}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}



export default Footer;
