import React from "react";
import {
    APP_NAME,
    DROP_DOWN_LINKS_ALT,
    PRIVACY_POLICY,
} from "../constants";
import Header from "../components/Headers/Header";
import BreadCrumbSection from '../components/Sections/BreadCrumbSection';
import PillAnimation from "../components/Animation/PillAnimation";
import PrivacyPolicySection from "../components/Sections/PrivacyPolicySection";
import ScrollToTop from "../components/Utilities/scrollToTop";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Header
                title={APP_NAME}
                dropDownText="More"
                dropDownLinks={DROP_DOWN_LINKS_ALT}
            />
            <BreadCrumbSection
                title="Privacy Policy"
                text="Curious about the data we collect and what we do with them?<br>"
            />
            <PillAnimation />
            <PrivacyPolicySection privacyPolicyData={PRIVACY_POLICY} />
            <ScrollToTop />
        </>
    )
};

export default PrivacyPolicyPage;