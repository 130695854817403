import React from "react";
import {
    APP_NAME,
    DROP_DOWN_LINKS,
    SERVICES,
    WHY_US,
    FAQ_DATA,
} from "../constants";
import Header from "../components/Headers/Header";
import MainSection from "../components/Sections/Main-Section";
import PillAnimation from "../components/Animation/PillAnimation";
import ServicesSection from "../components/Sections/Services-Section";
import RapidTransactionSection from "../components/Sections/Rapid-Transaction-Section";
import SMMSection from "../components/Sections/SMM-Section";
import WhyUsSection from "../components/Sections/Why-Us-Section";
import FaqSection from "../components/Sections/FAQSection";


const HomePage = (metaData) => {
    return (
        <>
            <Header
                title={APP_NAME}
                dropDownText="More"
                dropDownLinks={DROP_DOWN_LINKS}
            />
            <MainSection />
            <PillAnimation />
            <ServicesSection
                services={SERVICES}
            />
            <RapidTransactionSection />
            <SMMSection />
            <WhyUsSection
                whyUs={WHY_US}
            />
            <FaqSection
                faqData={FAQ_DATA}
            />
        </>
    );
};

export default HomePage;