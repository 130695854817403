import React from 'react';
import { Link } from 'react-router-dom'
import Icon from '../Utilities/icon';

import logoLight from '../../images/logo/logo-light.png';

function HeaderTitle({ title }) {
    return (
        <Link className="navbar-brand" to="/">
            <img src={logoLight} width="75" alt="Cash Point" />
            <span className="theme-text d-none d-xl-flex">
                {title}
            </span>
        </Link>
    );
}

function HeaderDropDown({ dropdownText, links }) {
    return (
        <ul className="navbar-nav ml-auto d-flex flex-row">
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {dropdownText}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    {links.map((link, index) => (
                        <DropDownLink key={index} {...link} />
                    ))}
                </ul>
            </li>
        </ul>
    )
}

function DropDownLink({ iconName, link, text }) {
    const isInternalLink = /^\/(?!\/)/.test(link);
    if (isInternalLink) {
        return (
            <li>
                <Link to={link} className="dropdown-item">
                    <Icon name={iconName} />
                    <span className="ms-2">{text}</span>
                </Link>
            </li>
        );
    } else {
        return (
            <li>
                <a href={link} className="dropdown-item">
                    <Icon name={iconName} />
                    <span className="ms-2">{text}</span>
                </a>
            </li>
        );
    }
}


function Header({ title, dropDownText, dropDownLinks }) {
    return (
        <header>
            <nav className="navbar sticky-top navbar-expand-md navbar-dark bg-black text-light">
                <div className="container">
                    <HeaderTitle title={title} />
                    <HeaderDropDown dropdownText={dropDownText} links={dropDownLinks} />
                </div>
            </nav>
        </header>
    );
}

export default Header;