import React from "react";

import logoColored from '../../images/logo/logo-theme.png';
import leftScreenImage from '../../images/phone/smm-half.png';
import centerScreenImage from '../../images/phone/home-half.png';
import providerImage from '../../images/others/providers.png';

function MainSection() {
    return (
        <section id="main-section" className="text-light">
            <div className="shadow-pill-parent">
                <div className="container">
                    <div className="row justify-center">
                        <div className="col-md-12 wavy">
                            <div className="image-rotation-container">
                                <div className="rotating-images-box">
                                    <img src={leftScreenImage} alt="SMM Cash Point" />
                                </div>
                                <div className="rotating-images-box">
                                    <img src={centerScreenImage} alt="Home Screen Cash Point" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="descriptor pt-5 pb-5">
                                            <div className="shout-out mb-2">
                                                <span>Welcome on board</span>
                                            </div>
                                            <img src={logoColored} alt="Cash Point Colored Logo"
                                                width="150" />
                                            <h2 className="theme-text mt-3">
                                                lets make bill payment
                                                <br />
                                                easy and faster
                                            </h2>
                                            <p className="mt-3">
                                                Say goodbye to long queues and complicated payment processes,<br />
                                                Say hello to a smarter and faster way to pay and manage bills.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="descriptor pt-5 pb-5">
                                            <h2 className="theme-text">
                                                Powered by your trusted service providers
                                            </h2>
                                            <img className="mt-4" src={providerImage} alt="" />
                                            <p className="mt-3"><i>And much more...</i></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-evenly">
                                    <h4 className="theme-text">No Delay</h4>
                                    <h4 className="theme-text">No Hassle</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainSection;