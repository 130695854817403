import React from "react";
import { PRIVACY_POLICY_EFFECTIVE_DATE } from '../../constants'

function PrivacyPolicyContent({ content }) {
    return (
        <div className="row">
            <div className="col-md-12">
                {content.map((item, index) => (
                    <ContentItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
}

function ContentItem({ type, text, items, linkText, linkHref }) {
    switch (type) {
        case "paragraph":
            return <p>{text}</p>;
        case "list":
            return (
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
        case "link":
            return (
                <p>
                    <a href={linkHref}>{linkText}</a>
                </p>
            );
        default:
            return null;
    }
}

function PrivacyPolicySection({ privacyPolicyData }) {
    return (
        <section id="Privacy-policy" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Your Privacy, Our Priority</span>
                        </div>
                        <h2 className="theme-text mt-3">Privacy Policy</h2>
                        <p dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_EFFECTIVE_DATE }}></p>
                    </div>
                    {privacyPolicyData.map((section, index) => (
                        <div key={index}>
                            <h2>{index + 1}. {section.title}</h2>
                            <PrivacyPolicyContent content={section.content} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicySection;