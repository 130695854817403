import { useForm } from "react-hook-form";
import Icon from "../Utilities/icon";
import { Notify, Block } from "notiflix";

function ContactSection() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const requiredData = "This data is required and must be valid";

    const contactSubmit = (formData) => {
        if (formData.phoneNumber.length < 11) {
            Notify.info('Phone number is invalid');
            return;
        }
        if (formData.message.length < 3) {
            Notify.info('Message too short');
            return;
        }
        if (formData.isVisitor !== '' || formData.isValidURL !== '') {
            return;
        }
        Block.arrows('#contactForm', 'Contacting ...')
        console.log(formData)
    };

    console.log(errors);

    return (
        <section id="contact-us" className="position-relative bg-white">
            <div className="pt-5 pb-5 wavy-dark">
                <div className="container">
                    <div className="section-header">
                        <div className="shout-out-dark">
                            <span>Connecting with Us</span>
                        </div>
                        <h2 className="theme-text mt-3">
                            Contact and Support
                        </h2>
                        <p>
                            Our commitment to you goes beyond just offering financial solutions. We believe in the power of
                            human connection and the
                            value of exceptional customer support. Your experience with Cash Point is a partnership, and
                            we're here to ensure that
                            every step of your journey is smooth, convenient, and backed by unwavering support.
                        </p>
                    </div>
                    <div id="contactForm" className="p-3 bg-black text-light rounded-3 mb-3">
                        <form onSubmit={handleSubmit(contactSubmit)}>
                            <div className="form-group mb-2">
                                <label htmlFor="fullName" className="form-label">
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="fullName"
                                    placeholder="Your Full Name"
                                    {...register("fullName", { required: true, })}
                                />
                                {errors.fullName && <small className="text-danger">{requiredData}</small>}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Your Email Address"
                                    {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                                />
                                {errors.email && <small className="text-danger">{requiredData}</small>}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phoneNumber"
                                    placeholder="Your Phone Number"
                                    {...register("phoneNumber", { required: true, })}
                                />
                                {errors.phoneNumber && <small className="text-danger">{requiredData}</small>}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="contactReason" className="form-label">Contact Reason</label>
                                <select
                                    name="contactReason"
                                    className="form-control"
                                    {...register("contactReason", { required: true, })}
                                >
                                    <option value="Feedback">Feed Back</option>
                                    <option value="Inquiry">Inquiry</option>
                                    <option value="Others">Others</option>
                                </select>
                                {errors.contactReason && <small className="text-danger">{requiredData}</small>}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea
                                    className="form-control"
                                    name="message"
                                    rows="6"
                                    placeholder="Your message"
                                    {...register("message", { required: true, })}
                                ></textarea>
                                {errors.message && <small className="text-danger">{requiredData}</small>}
                            </div>
                            <input type="hidden" name="isVisitor" {...register("isVisitor")} />
                            <input type="hidden" name="isValidURL" {...register("isValidURL")} />
                            <button type="submit" className="btn btn-lg btn-light">
                                <Icon name="Pocket" />
                                <span>Submit Request</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;
